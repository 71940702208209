import { flow, Instance, types } from 'mobx-state-tree';

import { getRootStore } from '../../../../../root/RootStoreUtils';
import {
  Get3DSValidationDetailResponse,
  PaymentMethodProviderType,
  ThreeDsCreditCardResponse
} from '../../../../../../external/shared/api/EnrollmentClient.generated';
import { apiClient } from '../../../../model/CreditCard';

function redirectToDoku({ acsUrl, paReq, md }: ThreeDsCreditCardResponse, termUrl: string) {
  const form = document.createElement('form');
  form.name = 'doku-three-d-secure-form';
  form.action = acsUrl;
  form.method = 'POST';
  const formPayload = {
    PaReq: paReq,
    MD: md,
    TermUrl: termUrl
  };
  for (const name in formPayload) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = formPayload[name as keyof typeof formPayload];
    form.appendChild(input);
  }
  document.body.appendChild(form);
  form.submit();
}

export const Doku3dsStore = types
  .model({})
  .volatile(() => ({
    initPromise: undefined as Promise<ThreeDsCreditCardResponse> | undefined
  }))
  .views(self => ({
    get isEnabled(): boolean {
      const { config, isProviderEnabled } = getRootStore(self).moduleStores.threeDSecureEntry!;
      return config.doku3dsEnabled && isProviderEnabled(PaymentMethodProviderType.Doku, false);
    },
    get termUrl() {
      const { redirectReturnUrl } = getRootStore(self).moduleStores.threeDSecureEntry!;

      const dokuGatewayUrl = window.location.hostname.startsWith('www.youngliving.com')
        ? 'https://doku-gateway.awsvoprd.youngliving.com'
        : 'https://doku-gateway-stage.awsvodev.youngliving.com';

      const termUrl = new URL('/api/doku/do3DSecure', dokuGatewayUrl);
      termUrl.searchParams.set('redirectUrl', redirectReturnUrl);

      return termUrl.href;
    }
  }))
  .actions(self => ({
    initAuth(): Promise<ThreeDsCreditCardResponse> {
      if (!self.initPromise) {
        self.initPromise = apiClient.getDoku3DSParams();
      }
      return self.initPromise;
    }
  }))
  .actions(self => ({
    auth: flow(function* auth(): any {
      const { threeDSecureEntry } = getRootStore(self).moduleStores;

      if (threeDSecureEntry!.isReturnedFromRedirect()) {
        const { success }: Get3DSValidationDetailResponse = yield apiClient.getDoku3DSResult();
        if (!success) {
          throw new Error(`Unsuccessful Doku 3DS Auth`);
        }
        return undefined;
      }

      const response: ThreeDsCreditCardResponse = yield self.initAuth();

      if (response.responseCode === '0000') {
        redirectToDoku(response, self.termUrl);
        yield new Promise(() => {
          // never return as redirect will happen
        });
      } else if (response.responseCode === '111') {
        throw new Error(`Unsuccessful Doku 3DS Auth: ${response.responseMessage}`);
      }

      return undefined;
    })
  }));

export interface Doku3dsStore extends Instance<typeof Doku3dsStore> {}
