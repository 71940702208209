import { types } from 'mobx-state-tree';
import {
  EnrollmentPaymentType,
  PaymentMethodProviderType
} from '../../../external/shared/api/EnrollmentClient.generated';
import { CreditCard } from '../../billing/model/CreditCard';
import { PaymentInstallment } from '../../billing/components/installment/PaymentInstallment';

export const AppliedPaymentsReviewStore = types
  .model({
    paymentDescription: types.string,
    amount: types.number,
    voucherAmount: types.maybe(types.number),
    selectedPaymentIdentifier: types.maybe(types.string),
    paymentType: types.maybe(
      types.enumeration<EnrollmentPaymentType>('EnrollmentPaymentType', Object.values(EnrollmentPaymentType))
    ),
    paymentMethodProviderType: types.maybe(
      types.enumeration<PaymentMethodProviderType>(Object.values(PaymentMethodProviderType))
    ),
    creditCardData: CreditCard,
    installment: types.maybe(PaymentInstallment)
  })
  .named('AppliedPaymentsReviewStore');
