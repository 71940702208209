/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import { ActivateButton } from './models/ActivateButton';
import { useRootStore } from '../root/RootStoreHooks';
import { EnrollmentRootStore } from '../root/EnrollmentRootStore';

export const CompleteEnrollment = observer(() => {
  const { showReturnPolicy, threeDSecureEntry, completeEnrollmentProcessor } = useRootStore().moduleStores;
  const { t } = useTranslation();

  const isReturnedFromRedirect = threeDSecureEntry?.isReturnedFromRedirect();
  useEffect(() => {
    if (isReturnedFromRedirect) {
      completeEnrollmentProcessor?.activateAndCheckout();
    }
  }, [completeEnrollmentProcessor, isReturnedFromRedirect]);

  return (
    <Row className="justify-content-end">
      {showReturnPolicy && (
        <Col md="auto" className="mb-3">
          <div dangerouslySetInnerHTML={{ __html: t('returnPolicyNote') }} />
        </Col>
      )}
      <Col md="auto">
        <ActivateButton />
      </Col>
    </Row>
  );
});

function determineLabelToUse(rootStore: EnrollmentRootStore) {
  const navigation = rootStore.stepsManager.currentStep.navigation;
  if (navigation.nextStepModule === 'CompleteNoPurchaseEnrollment') {
    return navigation.nextLabel;
  }
  return navigation.previousLabel;
}

export const CompleteNonPurchaseEnrollment = observer(function CompleteNonPurchaseEnrollment() {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const moduleStores = rootStore.moduleStores;

  if (rootStore.stepsManager.isAccordionLayout) {
    if (!moduleStores.earlyEnrollment) {
      return <></>;
    }
    if (moduleStores.activeModuleGroup || moduleStores.activeModuleGroup === 0) {
      return <></>;
    }
    if (!rootStore.stepsManager.currentStep.allModulesCompleted) {
      return <></>;
    }
  }

  const nextAction = moduleStores.earlyEnrollment
    ? () => rootStore.moduleStores.completeEnrollmentProcessor!.activateAndCheckout()
    : () => rootStore.stepsManager.nextStep();

  return (
    <Button block onClick={nextAction} variant="primary">
      {t(determineLabelToUse(rootStore))}
    </Button>
  );
});
