import { Instance, types } from 'mobx-state-tree';

export const DocumentInfo = types
  .model({
    name: types.string,
    originalName: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    size: types.maybeNull(types.number),
    file: types.frozen<File>()
  })
  .named('DocumentInfo');

export type DocumentInfo = Instance<typeof DocumentInfo>;
