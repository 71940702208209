import React from 'react';
import Form from 'react-bootstrap/Form';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useObserver } from 'mobx-react-lite';
import { YlInput } from '../../../infrastructure/forms/YlInput';

export const GovernmentEntry = () => {
  const governmentEntryStore = useModuleStore('governmentEntry');

  return useObserver(() => {
    return (
      <>
        <Form className="governmentForm">
          <Form.Row>
            <YlInput
              id="governmentId"
              required={true}
              customAttributes={{ maxLength: 16 }}
              field={governmentEntryStore.reactForm.$.governmentId}
              label="governmentId"
              size={4}
            />
          </Form.Row>
        </Form>
      </>
    );
  });
};
