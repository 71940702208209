import { Instance, types } from 'mobx-state-tree';
import { flow } from 'mobx-state-tree';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { DocumentInfo } from './model/DocumentInfo';

export const DocumentsUploadEntryStore = types
  .model({
    selectedFiles: types.optional(types.array(DocumentInfo), []),
    uploadedFiles: types.optional(types.array(DocumentInfo), [])
  })
  .actions(self => ({
    onDrop: (acceptedFiles: File[]) => {
      const fileDataArray = acceptedFiles.map(file => ({
        name: file.name,
        type: file.type,
        size: file.size,
        file
      }));
    
      self.selectedFiles.push(...fileDataArray);
    },
    uploadFilesToApi: flow(function* () {
      const formData = new FormData();
      self.selectedFiles.forEach(fileData => {
        formData.append('files', fileData.file);
      });
      try {
        const response = yield enrollmentResource.post('/documents', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        self.selectedFiles.forEach(fileData => {
          self.uploadedFiles.push({ ...fileData });
        });
        self.selectedFiles.clear();
        console.log('Response from server:', response.data);
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }),
    removeUploadedFile: flow(function* (fileName: string) {
      try {
        yield enrollmentResource.delete('/documents/' + fileName);
        self.uploadedFiles.replace(self.uploadedFiles.filter(file => file.name !== fileName));
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }),
    removeSelectedFile(fileName: string) {
      self.selectedFiles.replace(self.selectedFiles.filter(file => file.name !== fileName));
    }
  }))
  .views(self => ({
    getDataToSubmit() {
      const formData = {
        uploadedDocumets: self.uploadedFiles
      };
      return formData;
    },
    canProceed() {
      return self.uploadedFiles.length > 0;
    }
  }));

export type DocumentsUploadEntryStore = Instance<typeof DocumentsUploadEntryStore>;
