import { Instance, types } from 'mobx-state-tree';
import { AgreementDocument } from '../agreement/model/AgreementDocument';

export const TaxFormsAgreementEntryStore = types
  .model({
    text: types.optional(types.string,''),
    documents: types.optional(types.array(AgreementDocument),[])
  })
  .views(self => ({
      getDataToSubmit() {
        return { isAccepted: true } ;
      }
  }))
  .named('TaxFormsAgreementEntryStore');

export type TaxFormsAgreementEntryStore = Instance<typeof TaxFormsAgreementEntryStore>;
