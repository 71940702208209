import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { CreditCard } from '../../model/CreditCard';

interface DisplayItemProps {
  label: string;
  value?: string;
}
const DisplayItem: FC<DisplayItemProps> = ({ label, value }) => {
  const { t } = useTranslation();
  return (
    <p>
      <span>{t(label)}:</span>
      <span className="text-primary"> {value}</span>
    </p>
  );
};

interface Props {
  creditCard: CreditCard;
}

export const CreditCardDisplay: FC<Props> = ({ creditCard }) => {
  return useObserver(() => (
    <>
      <DisplayItem label="NameOnCard" value={creditCard.name} />
      <DisplayItem label="CardNumber" value={creditCard.obfuscatedNumber || creditCard.number} />
      <DisplayItem
        label="ExpirationDate"
        value={`${creditCard.expirationMonth!.toString().padStart(2, '0')}/${creditCard.expirationYear}`}
      />
    </>
  ));
};
