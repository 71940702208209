import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

import { useTranslation } from 'react-i18next';
import { EnrollmentStep } from '../../../model/EnrollmentStep';
import { ModuleRenderer } from '../../../../module-management/ModuleRenderer';
import { CompleteEnrollment } from '../../../../../complete-enrollment/CompleteEnrollment';
import { CvvForPurchaseModal } from '../../../../../complete-enrollment/modals/CvvForPurchaseModal';
import { OrderReturnPolicyReview } from '../../../../../order-review/order-return-policy-review/OrderReturnPolicyReview';
import { OrderReviewErrorModal } from '../../../../../complete-enrollment/modals/OrderReviewErrorModal';
import { OrderReviewPolicyModal } from '../../../../../complete-enrollment/modals/OrderReviewPolicyModal';
import { PaymentFailureModal } from '../../../../../complete-enrollment/modals/FailureModal';
import { Braintree3ds } from '../../../../../billing/components/credit-card/three-d-secure/braintree-3ds/Braintree3ds';
import { Chase3ds } from '../../../../../billing/components/credit-card/three-d-secure/chase-3ds/Chase3ds';
import { Cybersource3ds } from '../../../../../billing/components/credit-card/three-d-secure/cybersource-3ds/Cybersource3ds';
import { Doku3ds } from '../../../../../billing/components/credit-card/three-d-secure/doku-3ds/Doku3ds';
import { Nuvei3ds } from '../../../../../billing/components/credit-card/three-d-secure/nuvei-3ds/Nuvei3ds';
import { Stripe3ds } from '../../../../../billing/components/credit-card/three-d-secure/stripe-3ds/Stripe3ds';
import { VirtualWallet } from '../../../../../billing/components/virtual-wallet/VirtualWallet';
import { ExceliaAgreementInfoModal } from '../../../../../complete-enrollment/modals/ExceliaAgreementInfoModal';
import { ReviewTopBanner } from '../../../../../order-review/top-banner/ReviewTopBanner';
import { MemberAgreementOrderReview } from '../../../../../order-review/member-agreement-order-review/MemberAgreementOrderReview';

interface Props {
  step: EnrollmentStep;
}

export const ReviewPageStepLayout = observer<Props>(({ step }) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="h5 mb-5">{t('ActivateMembershipHelp')}</p>
      <MemberAgreementOrderReview></MemberAgreementOrderReview>
      <Row>
        <Col md={9}>
          <ReviewTopBanner />
        </Col>
        <Col md={3}>
          <CompleteEnrollment />
        </Col>
      </Row>
      <h2 className="text-primary">{t('MemberInformation')}</h2>
      <Row>
        <Col md={4} className="d-flex flex-column">
          {step.layoutModuleGroups[0].modules.map(module => (
            <ModuleRenderer key={module} module={module} />
          ))}
        </Col>
        {step.layoutModuleGroups[1].modules.map(module => (
          <Col md={4} key={module}>
            <ModuleRenderer key={module} module={module} />
          </Col>
        ))}
      </Row>
      <Row>
        <Col md={8}>
          {step.layoutModuleGroups[2].modules.map(module => (
            <ModuleRenderer key={module} module={module} />
          ))}
        </Col>
        <Col md={4}>
          {step.layoutModuleGroups[3].modules.map(module => (
            <ModuleRenderer key={module} module={module} />
          ))}
        </Col>
      </Row>
      <CvvForPurchaseModal />
      <OrderReturnPolicyReview />
      <OrderReviewPolicyModal />
      <OrderReviewErrorModal />
      <PaymentFailureModal />
      <Braintree3ds />
      <Chase3ds />
      <Cybersource3ds />
      <Doku3ds />
      <Nuvei3ds />
      <Stripe3ds />
      <VirtualWallet />
      <ExceliaAgreementInfoModal />
    </>
  );
});
