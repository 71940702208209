import { Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { required } from '../shared/validators/RequiredValidator';

export const GovernmentEntryStore = types
  .model({
    governmentId: types.string
  })
  .actions(self => ({
    updateStoreFromForm(formData: any) {
      self.governmentId = formData.governmentId;
    }
  }))
  .views(self => ({
    get reactForm() {
      const governmentIdField = new FieldState(self.governmentId).validators(
        required()
      );
      return new FormState({
        governmentId: governmentIdField
      });
    },
    getDataToSubmit() {
      const form = this.reactForm;
      const formData = {
        governmentId: form.$.governmentId.value
      };
      self.updateStoreFromForm(formData);
      return formData;
    },
    canProceed() {
      return !this.reactForm.hasError;
    }
  }))
  .actions(self => ({
    validateForm() {
      return self.reactForm.validate();
    }
  }));

export type GovernmentEntryStore = Instance<typeof GovernmentEntryStore>;
