import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../../../root/RootStoreHooks';

export const Doku3ds = observer(() => {
  const rootStore = useRootStore();
  const { billingEntry, threeDSecureEntry: { doku3ds } = {} } = rootStore.moduleStores;
  const { isLastStep } = rootStore.stepsManager;
  const { hasUserInput } = billingEntry?.creditCardData ?? {};
  useEffect(() => {
    if (doku3ds?.isEnabled) {
      if (isLastStep || hasUserInput) {
        doku3ds.initAuth();
      }
    }
  }, [doku3ds, doku3ds?.isEnabled, hasUserInput, isLastStep]);

  return null;
});
