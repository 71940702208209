import React, { FC } from 'react';
import { useObserver } from 'mobx-react-lite';
import { SnapshotOut } from 'mobx-state-tree';
import { Currency, getDecimalPrice } from '../../../../reference/Currency';

interface Props {
  price: number;
  currency: SnapshotOut<typeof Currency>;
}

export const MoneyDisplay: FC<Props> = ({ price, currency }) => {
  const formattedPrice = getDecimalPrice(price, currency.code);
  return useObserver(() => (
    <>
      {currency.symbol}
      {formattedPrice}
      <small> {currency.code}</small>
    </>
  ));
};
