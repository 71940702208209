import { flow, Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { getFormValues } from '@yl/react-forms';

import { required } from '../../customer-information/shared/validators/RequiredValidator';
import { WireTransferFormModel } from '../../../external/shared/api/EnrollmentClient.generated';

export const WireTransfer = types
    .model({
        bankOption: types.maybe(types.string),
    })
    .views(self => ({
        get reactForm() {
            const fields: { [key: string]: FieldState<any> } = {
                bankOption: new FieldState(self.bankOption || '').validators(required()),
            };

            return new FormState(fields);
        },
        canProceed() {
            if (self.bankOption) {
                return true;
            }
            return !this.reactForm.hasError;
        }
    }))
    .actions(self => ({
        prepareResults: flow(function* prepareResults() {
            if (self.bankOption) {
                return Promise.resolve(true);
            }
            yield self.reactForm.validate();
            return !self.reactForm.hasError;
        }),
        getDataToSubmit() {
            const form = getFormValues(self.reactForm);
            return {
                bankOption: form.bankOption,
            } as WireTransferFormModel;
        },
    }));

export type WireTransfer = Instance<typeof WireTransfer>;
