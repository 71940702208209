import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, useObserver } from 'mobx-react-lite';
import { useRootStore } from '../../../root/RootStoreHooks';
import { YlDropdown } from '../../../../infrastructure/forms/YlDropdown';
import { Form } from 'react-bootstrap';

export const WireTransferEditor = observer(() => {
  const rootStore = useRootStore();
  const { t } = useTranslation();
  const billing = rootStore.moduleStores.billingEntry!;
  const wireTransfer = billing.wireTransferData;
  const wireTransferForm = wireTransfer.reactForm;
  const bankOptions = [{ id: "BCA", name: "BCA" }, { id: "BRI", name: "BRI" }];

  return (
    <Form className="mb-n3">
      <Form.Row>
        <YlDropdown
          required
          size={6}
          id="wireTransferBankOptions"
          options={bankOptions}
          valueFn={bankOption => bankOption.id}
          labelFn={bankOption => bankOption.name}
          disabled={false}
          field={wireTransferForm.$.bankOption}
          label=""
        />
      </Form.Row>
      <Form.Row>
        <div
          dangerouslySetInnerHTML={{ __html: t('WireTransferDescription') }}
        />
      </Form.Row>
    </Form>
  );
});
