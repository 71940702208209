import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { YlInput } from '../../../infrastructure/forms/YlInput';
import { useRootStore } from '../../root/RootStoreHooks';
import { CreditCardTypes } from '../../../external/shared/api/EnrollmentClient.generated';

export const CvvForPurchaseModal = observer(() => {
  const { appliedPaymentsReview, completeEnrollmentProcessor } = useRootStore().moduleStores;
  const cc = appliedPaymentsReview!.creditCardData;
  const store = completeEnrollmentProcessor!.cvvForPurchaseModal;

  const { t } = useTranslation();

  return (
    <Modal show={store.showModal} onHide={() => store.hideModal()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t('CvvDialogTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <YlInput
          required
          id="cvv"
          size={12}
          type="password"
          field={store.reactForm.$.cvv}
          label="Credit_CCV"
          customAttributes={{
            autoComplete: 'cc-csc',
            minLength: 3,
            maxLength: cc.cardType?.value === CreditCardTypes.AMEX ? 4 : 3
          }}
          onChange={() => store.applyFormat(store.reactForm.$.cvv)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => store.hideModal()}>
          {t('CvvDialogCancel')}
        </Button>
        <Button variant="primary" onClick={() => store.associateCvv()} disabled={store.reactForm.hasError}>
          {t('CvvDialogSubmit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
