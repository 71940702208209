import { applySnapshot, flow, Instance, types } from 'mobx-state-tree';
import { FieldState, FormState, Validator } from 'formstate';
import { getFormValues } from '@yl/react-forms/dist';
import { AddressEntryFormData } from './AddressEntryFormData';
import { AddressModuleForm } from './module-form/AddressModuleForm';
import { AddressRow } from './module-form/AddressRow';
import { PostalCodeValidation } from './PostalCodeValidation';
import { maxByteSize } from './validators/max-byte-size';
import { matchesRegExp } from '../../customer-information/shared/validators/RegularExpressionValidator';
import { AddressValidator } from '../validator/AddressValidator';
import { required } from '../../customer-information/shared/validators/RequiredValidator';
import { PhoneValidation } from '../../customer-information/phone/PhoneValidation';
import { Suburb } from '../../../reference/Suburb';
import { replaceUnallowedCharacters } from '../../../infrastructure/forms/InputTextFormatter';
import { ComponentField } from '../../../infrastructure/dynamic-forms/ComponentField';
import { enrollmentResource } from '../../../infrastructure/http/EnrollmentResource';
import { getRootStore } from '../../root/RootStoreUtils';
import { City } from '../../../reference/City';
import { postalCodeFormatter } from '../../../infrastructure/forms/postalCodeFormatter';

export const AddressEntryStore = types
  .model({
    form: AddressModuleForm,
    data: AddressEntryFormData,
    fieldRegularExpression: types.string,
    postalCodeValidation: types.maybe(PostalCodeValidation),
    phoneValidation: types.maybe(PhoneValidation),
    storeId: types.number,
    shouldValidateAddress: types.boolean,
    addressValidator: types.optional(AddressValidator, {}),
    showMarketChangeWarning: false,
    invalidPostalCodeMessage: '',
    lastPostalCode: '',
    inEditMode: types.boolean,
    shouldConfirmAddress: types.boolean,
    targetCountry: types.maybe(types.string),
    targetStateProv: types.maybe(types.string),
    targetCity: types.maybe(types.string),
    jpZipNotFound: false
  })
  .actions(self => ({
    updateFromForm(data: any) {
      if (self.data === undefined) {
        self.data = AddressEntryFormData.create();
      }

      self.data.street1 = data.street1;
      self.data.street2 = data.street2;
      self.data.street3 = data.street3;
      self.data.city = data.city;
      self.data.stateProv = data.stateProv;
      self.data.postalCode = data.postalCode;
      self.data.country = data.country;
      self.jpZipNotFound = data.jpZipNotFound;
      //optional fields
      if (data.neighborhood) {
        self.data.neighborhood = data.neighborhood;
      }
      if (data.houseNumber) {
        self.data.houseNumber = data.houseNumber;
      }
      if (data.interiorNumber) {
        self.data.interiorNumber = data.interiorNumber;
      }
    },
    async tryAutoFillData(data: any, countryIsoCode: string, postalCode: string): Promise<void> {
      const response = await enrollmentResource.get<any>(`/latam-address/${countryIsoCode}/${postalCode}`);
      data.postalCode = postalCode;

      if (response.data.city) {
        data.city = response.data.city;
      }
      if (response.data.state) {
        const states = getRootStore(self).reference.getStatesForCountry("MX");
        const responseStateInUpperCase = response.data.state.toUpperCase();
        let state = states?.find(s => s.name.toUpperCase() == responseStateInUpperCase);

        if (!state){
          state = states?.find(s => responseStateInUpperCase.includes(s.name.toUpperCase()) ||
              s.name.toUpperCase().includes(responseStateInUpperCase));
        }

        if (state) {
          data.stateProv = state.abbreviation;
        }
      }
      if (response.data.municipality) {
        data.neighborhood = response.data.municipality;
      }
      this.updateFromForm(data);
      this.disableFormFieldsIfNeeded(response.data);
      this.updatePostalCodeValidationMessage(response.data);
    },
    async tryAutoFillJpFormData(data: any, postalCode: string): Promise<void> {
      const response = await enrollmentResource.get<any>(`/zip-to-address-japan/${postalCode}`);
      data.postalCode = postalCode;

      data.jpZipNotFound = !response.data.found;

      if (response.data.city) {
        data.city = response.data.city;
      }
      if (response.data.prefecture) {
        const states = getRootStore(self).reference.getStatesForCountry("JP");
        const responseStateInUpperCase = response.data.prefecture.toUpperCase();
        let state = states?.find(s => s.name.toUpperCase() == responseStateInUpperCase);

        if (!state){
          state = states?.find(s => responseStateInUpperCase.includes(s.name.toUpperCase()) ||
              s.name.toUpperCase().includes(responseStateInUpperCase));
        }

        if (state) {
          data.stateProv = state.abbreviation;
        }
      }
      if (response.data.street1) {
        data.street1 = response.data.street1;
      }

      this.updateFromForm(data);
    },
    async tryAutoFillIdFormData(data: any, postalCode: string): Promise<void> {
      const cityResponse = await enrollmentResource.get<any>(`/countries/ID/postalCodes/${postalCode}/cities`);
      const stateResponse = await enrollmentResource.get<any>(`/countries/ID/postalCodes/${postalCode}/states`);
      data.postalCode = postalCode;

      if (cityResponse.data.length) {
        data.city = cityResponse.data[0].name;
      }
      if (stateResponse.data.length) {
        const stateName = stateResponse.data[0].name;
        const states = getRootStore(self).reference.getStatesForCountry("ID");
        const responseStateInUpperCase = stateName.toUpperCase();
        let state = states?.find(s => s.name.toUpperCase() == responseStateInUpperCase);

        if (!state){
          state = states?.find(s => responseStateInUpperCase.includes(s.name.toUpperCase()) ||
              s.name.toUpperCase().includes(responseStateInUpperCase));
        }

        if (state) {
          data.stateProv = state.abbreviation;
        }
      }

      this.updateFromForm(data);
    },
    disableFormFieldsIfNeeded(data: any) {
      self.form.rows.forEach((row: AddressRow) => {
        row.formFields.forEach(field => {
          if (data.city && field.name === 'city') {
            field.disabled = true;
          }
          if (data.state && field.name === 'stateProv') {
            field.disabled = true;
          }
        });
      });
    },
    
    updatePostalCodeValidationMessage(data: any) {
      const isPostalCodeValid = data.city || data.state || data.municipality;

      if (isPostalCodeValid) {
        self.invalidPostalCodeMessage = "";
      }
      else {
        self.invalidPostalCodeMessage = "Invalid postal code";
      }
    }
  }))
  .views(self => ({
    get supportsDisplay(): boolean {
      return true;
    },
    get reactForm() {
      const forms = {} as any;
      const formValues = self.data as any;
      self.form.rows.forEach((row: AddressRow) => {
        row.formFields.forEach(field => {
          const fieldState = new FieldState(formValues[field.name]);
          const validators: Validator<any>[] = [];
          if (field.required) {
            validators.push(required());
          }
          if (field.fieldType === 'Input') {
            validators.push(maxByteSize(field.maxLength));
            validators.push(matchesRegExp(self.fieldRegularExpression, 'InvalidAddress'));
          }

          if (field.name === 'postalCode' && self.postalCodeValidation) {
            validators.push(matchesRegExp(self.postalCodeValidation.validRegEx, 'VerifyPostalcodePatternError'));
            validators.push(matchesRegExp(self.postalCodeValidation.allowedCharactersRegEx, 'InvalidZipCode'));
          }

          fieldState.validators(...validators);
          forms[field.name] = fieldState;
        });
      });

      return new FormState(forms);
    },
    get isFormDirty() {
      return Object.values(this.reactForm.$ as FieldState<any>).some(x => x.dirty);
    },
    getDataToSubmit() {
      const toSubmit = {
        ...getFormValues(this.reactForm),
        isValidated: self.data.isValidated
      };
      self.updateFromForm(toSubmit);

      return toSubmit;
    },

    canProceed() {
      return !self.inEditMode || !this.reactForm.hasError;
    },

    setAllowCustomSuburb(noSuburb: boolean) {
      self.form.rows.forEach((row: AddressRow) => {
        row.formFields.forEach(field => {
          if (field.name === 'stateProv') {
            field.required = !noSuburb;
          }
          if (field.name === 'suburb') {
            field.required = !noSuburb;
          }
          if (field.name === 'postalCode') {
            field.disabled = !noSuburb;
          }
        });
      });
    }
  }))
  .actions(self => ({
    validateForm() {
      if (!self.inEditMode) {
        return Promise.resolve(true);
      }

      if (self.isFormDirty) {
        self.data.isValidated = false;
      }
      return self.reactForm.validate();
    },
    applyFormat: flow(function* debounceFormat(field: ComponentField) {
      yield new Promise(r => setTimeout(r, 1000));
      replaceUnallowedCharacters(self.reactForm.$[field.name], field.formatRegexForReplace);
    }),
    applyFormatByCountry: flow(function* debounceFormat(field: ComponentField, countryIso: string) {
      if (field.name === 'postalCode') {
        postalCodeFormatter(self.reactForm.$[field.name], countryIso);
      }

      yield new Promise(r => setTimeout(r, 1000));
      replaceUnallowedCharacters(self.reactForm.$[field.name], field.formatRegexForReplace);

    }),
    tryAutoFillMxData(field: ComponentField) {
      const formFieldValue = self.reactForm.$[field.name].value;

      if (field.name === 'postalCode' && getRootStore(self).enrollmentStatus.countryIso === 'MX' &&
        formFieldValue.length === 5 && formFieldValue !== self.lastPostalCode) {
        self.lastPostalCode = formFieldValue;
        self.tryAutoFillData({ ...getFormValues(self.reactForm) }, 'MEX', formFieldValue);
      }
    },
    tryAutoFillJpData(field: ComponentField) {
      const postalCodeFieldValue = self.reactForm.$[field.name].value;

      if (field.name === 'postalCode' && getRootStore(self).enrollmentStatus.countryIso === 'JP' &&
      postalCodeFieldValue.length >= 7 && postalCodeFieldValue.length <= 8 && postalCodeFieldValue !== self.lastPostalCode) {
        self.lastPostalCode = postalCodeFieldValue;
        self.tryAutoFillJpFormData({ ...getFormValues(self.reactForm) }, postalCodeFieldValue);
      }
    },
    tryAutoFillIdData(field: ComponentField) {
      const postalCodeFieldValue = self.reactForm.$[field.name].value;

      if (field.name === 'postalCode' && getRootStore(self).enrollmentStatus.countryIso === 'ID' &&
      postalCodeFieldValue.length === 5 && postalCodeFieldValue !== self.lastPostalCode) {
        self.lastPostalCode = postalCodeFieldValue;
        self.tryAutoFillIdFormData({ ...getFormValues(self.reactForm) }, postalCodeFieldValue);
      }
    },
    warnAboutMarketChange(country: string) {
      self.showMarketChangeWarning = true;
      self.targetCountry = country;
    },
    setStateProv(stateProv: string) {
      self.targetStateProv = stateProv;
    },
    setCity(city: string) {
      self.targetCity = city;
    },
    UpdateSuburb(suburb: Suburb) {
      self.reactForm.$.postalCode.value = suburb.postalCode;
    },
    UpdateCity(city: City) {
      self.reactForm.$.city.values = city.name;
    },
    UpdateNoSuburb(noSuburb: boolean) {
      self.data.stateProv = self.reactForm.$.stateProv.value;
      self.data.noSuburb = noSuburb;
      if (noSuburb === true) {
        self.reactForm.$.suburb.value = null;
        self.reactForm.$.postalCode.value = null;
      }
      self.setAllowCustomSuburb(noSuburb);
    },
    setInEditMode() {
      self.inEditMode = true;
    },
    cancelMarketChange() {
      self.reactForm.$.country.value = self.data.country!;
      self.reactForm.$.country.$ = self.data.country!;
      self.showMarketChangeWarning = false;
    },
    prepareResults: flow(function* prepareResults() {
      if (!self.shouldValidateAddress || !self.isFormDirty) {
        return true;
      }
      const selectedAddress = yield self.addressValidator.validateAddress(self.getDataToSubmit());

      if (!selectedAddress) {
        return false;
      }
      applySnapshot(self.data, selectedAddress);
      return true;
    })
  }));

export type AddressEntryStore = Instance<typeof AddressEntryStore>;
