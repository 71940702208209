import { useDropzone } from 'react-dropzone';
import { FaTrashAlt } from 'react-icons/fa';
import { Col, Form, Button } from 'react-bootstrap';
import React from 'react';
import { useModuleStore } from '../../enrollment-process/module-management/model/ModuleStoreHook';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import './DocumentsUploadEntry.scss';

export const DocumentsUploadEntry = () => {
  const { t } = useTranslation();
  const documentsUploadEntry = useModuleStore('documentsUploadEntry');

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: documentsUploadEntry.onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: true,
  });

  return useObserver(() => {
    return (
      <Form className="documentsUploadForm">
        <Form.Row>
          <h3 style={{ color: '#6e4a9' }}>{t('uploadTitle')}</h3>
        </Form.Row>

        <Form.Row>
          <h5>{t('uploadInfoTitle')}</h5>
          <p>{t('uploadInfo')}</p>
        </Form.Row>

        <Form.Row>
          <h3 style={{ color: '#707070' }}>{t('uploadNote')}</h3>
        </Form.Row>

        <Form.Row>
          <Col>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>{t('dragAndDrop')}</p>
              <p>{t('fileTypes')}</p>
              <Button variant="outline-primary">{t('selectFilesButton')}</Button>
            </div>
          </Col>

          <Col className="text-center">
            <h5>{t('uploadedDocuments')}</h5>
            {documentsUploadEntry.uploadedFiles.length > 0 && (
              <div className="uploaded-documents">
                {documentsUploadEntry.uploadedFiles.map((file, index) => (
                  <div key={index} className="file-info">
                    <span>{file.name}</span>
                    <Button variant="link" onClick={() => documentsUploadEntry.removeUploadedFile(file.name)}>
                      <FaTrashAlt />
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </Col>
        </Form.Row>

        <Form.Row className="text-center">
          {documentsUploadEntry.selectedFiles.length > 0 && (
            <div className="selected-documents">
              <h5>{t('selectedDocuments')}</h5>
              {documentsUploadEntry.selectedFiles.map((file, index) => (
                <div key={index} className="file-info">
                  <span>{file.name}</span>
                  <Button variant="link" onClick={() => documentsUploadEntry.removeSelectedFile(file.name)}>
                    <FaTrashAlt />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </Form.Row>

        <Form.Row className="upload-section">
          <Button disabled={documentsUploadEntry.selectedFiles.length === 0} onClick={documentsUploadEntry.uploadFilesToApi}>
            {t('selectedFiles')}
          </Button>
        </Form.Row>
      </Form>
    );
  });
};