import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { ErKitSelection } from '../../order-selection/er-kit-selection/ErKitSelection';
import { AddProducts } from '../../order-selection/add-products/AddProducts';
import { MeoKitSelection } from '../../order-selection/minimum-enrollment-order/MeoKitSelection';
import { CoApplicantEntry } from '../../customer-information/co-applicant/CoApplicantEntry';
import { AgeEntry } from '../../customer-information/age/AgeEntry';
import { EmailEntry } from '../../customer-information/email/EmailEntry';
import { PinEntry } from '../../customer-information/pin/PinEntry';
import { PhoneEntry } from '../../customer-information/phone/PhoneEntry';
import { AccountCredentialEntry } from '../../customer-information/account-credentials/AccountCredentialEntry';
import { SponsorEnrollerEntry } from '../../customer-information/sponsor-enroller/SponsorEnrollerEntry';
import { EnrollmentModule } from '../steps-management/model/LayoutModuleGroup';
import { NameEntry } from '../../customer-information/name/NameEntry';
import { StudentEntry } from '../../customer-information/student/StudentEntry';
import { GovernmentEntry } from '../../customer-information/government/GovernmentEntry';
import { DocumentsUploadEntry } from '../../customer-information/documents-upload/DocumentsUploadEntry';
import { EnrollmentKitSelection } from '../../order-selection/starter-kit-selection/EnrollmentKitSelection';
import { BillingEntry } from '../../billing/components/BillingEntry';
import { GenderEntry } from '../../customer-information/gender/GenderEntry';
import { ShippingMethodSelection } from '../../shipping/method-selection/ShippingMethodSelection';
import { MembershipReview } from '../../order-review/membership-review/MembershipReview';
import { PersonalInfoReview } from '../../order-review/personal-info/PersonalInfoReview';
import { ShippingAndBillingReview } from '../../order-review/shipping-and-billing-review/ShippingAndBillingReview';
import { AppliedPaymentsReview } from '../../order-review/applied-payments-review/AppliedPaymentsReview';
import { AddressEntry } from '../../address/main-address/AddressEntry';
import { ShippingAddressEntry } from '../../address/shipping-address/ShippingAddressEntry';
import { OrderTotalReview } from '../../order-review/order-total-review/OrderTotalReview';
import { OrderSummary } from '../../order-review/order-summary/OrderSummary';
import { MemberAgreementEntry } from '../../customer-information/member-agreement/MemberAgreementEntry';
import { TaxIdEntry } from '../../customer-information/tax-id/TaxIdEntry';
import { TaxFormsAgreementEntry } from '../../customer-information/tax-forms-agreement/TaxFormsAgreementEntry';
import { CompleteEnrollment, CompleteNonPurchaseEnrollment } from '../../complete-enrollment/CompleteEnrollment';
import { GstAgreementEntry } from '../../customer-information/gst-agreement/GstAgreementEntry';
import { PromoVoucherEntry } from '../../order-review/promo-voucher-entry/PromoVoucherEntry';
import { ClassicErKitSelection } from '../../order-selection/classic-er-kit-selection/ClassicErKitSelection';
import { ShareYoungLiving } from '../../customer-information/share-youngliving/ShareYoungLiving';
import { PreferencesEntry } from '../../customer-information/preferences/PreferencesEntry';
import { ReferrerReview } from '../../order-review/sponsor-enroller-review/ReferrerReview';
import { ContinueEnrollment } from '../../order-selection/continue-enrollment/ContinueEnrollment';
import { DirectMarketingAgreementEntry } from '../../customer-information/direct-marketing-agreement/DirectMarketingAgreementEntry';
import { SocialLinkEntry } from '../../social-link/SocialLinkEntry';
import { CreateSocialLinkButton } from '../../social-link/creator-mode/CreateSocialLinkButton';
import { VisitMainsite } from '../../social-link/visit-mainsite/VisitMainsite';
import { SocialLinkCreatorBanner } from '../../social-link/creator-mode/creator-mode-banner/SocialLinkCreatorBanner';
import { SocialLinkErKitSelection } from '../../social-link/creator-mode/SocialLinkErKitSelection';
import { CaptchaEntry } from '../../billing/components/captcha/CaptchaEntry';
import { AgreementModal } from '../../complete-enrollment/modals/AgreementModal';
import { ConfirmMemberInfoEntry } from '../../customer-information/confirmation/ConfirmMemberInfoEntry';
import { CustomerTypeEntry } from '../../customer-information/customer-type/CustomerTypeEntry';
import { ReviewTopBanner } from '../../order-review/top-banner/ReviewTopBanner';

interface ModuleRendererProps {
  module: EnrollmentModule;
}

export const ModuleRenderer = ({ module }: ModuleRendererProps) => {
  return useObserver(() => {
    switch (module) {
      case 'SocialLinkEntry':
        return <SocialLinkEntry />;
      case 'CreateSocialLinkButton':
        return <CreateSocialLinkButton />;
      case 'SocialLinkCreatorBanner':
        return <SocialLinkCreatorBanner />;
      case 'SocialLinkErKitSelection':
        return <SocialLinkErKitSelection />;
      case 'VisitMainsite':
        return <VisitMainsite />;
      case 'EnrollmentKitSelection':
        return <EnrollmentKitSelection />;
      case 'ErKitSelection':
        return <ErKitSelection />;
      case 'ClassicErKitSelection':
        return <ClassicErKitSelection />;
      case 'AddProducts':
        return <AddProducts />;
      case 'MeoKitSelection':
        return <MeoKitSelection />;
      case 'NameEntry':
        return <NameEntry />;
      case 'StudentEntry':
        return <StudentEntry />;
      case 'GovernmentEntry':
        return <GovernmentEntry />;
      case 'DocumentsUploadEntry':
        return <DocumentsUploadEntry />;
      case 'CustomerTypeEntry':
        return <CustomerTypeEntry />;
      case 'AgeEntry':
        return <AgeEntry />;
      case 'CoApplicantEntry':
        return <CoApplicantEntry />;
      case 'AddressEntry':
        return <AddressEntry />;
      case 'EmailEntry':
        return <EmailEntry />;
      case 'PhoneEntry':
        return <PhoneEntry />;
      case 'AccountCredentials':
        return <AccountCredentialEntry />;
      case 'PinEntry':
        return <PinEntry />;
      case 'TaxIdEntry':
        return <TaxIdEntry />;
      case 'SponsorEnroller':
        return <SponsorEnrollerEntry />;
      case 'BillingEntry':
        return <BillingEntry />;
      case 'ShippingMethodSelection':
        return <ShippingMethodSelection />;
      case 'ShippingAddressEntry':
        return <ShippingAddressEntry />;
      case 'GenderEntry':
        return <GenderEntry />;
      case 'MembershipReview':
        return <MembershipReview />;
      case 'PersonalInfoReview':
        return <PersonalInfoReview />;
      case 'ShippingAndBillingReview':
        return <ShippingAndBillingReview />;
      case 'AppliedPaymentsReview':
        return <AppliedPaymentsReview />;
      case 'OrderTotalReview':
        return <OrderTotalReview />;
      case 'OrderSummary':
        return <OrderSummary />;
      case 'MemberAgreement':
        return <MemberAgreementEntry />;
      case 'CompleteEnrollment':
        return <CompleteEnrollment />;
      case 'CompleteNoPurchaseEnrollment':
        return <CompleteNonPurchaseEnrollment />;
      case 'GstAgreement':
        return <></>; //We are rendering GstAgreement inside BusinessTaxEntry
      case 'TaxFormsAgreement':
        return <TaxFormsAgreementEntry />;
      case 'PromoVoucherEntry':
        return <PromoVoucherEntry />;
      case 'ShareYoungLiving':
        return <ShareYoungLiving />;
      case 'PreferencesEntry':
        return <PreferencesEntry />;
      case 'ReferrerReview':
        return <ReferrerReview />;
      case 'ContinueEnrollment':
        return <ContinueEnrollment />;
      case 'DirectMarketingAgreementEntry':
        return <DirectMarketingAgreementEntry />;
      case 'PrivacyPolicyAgreementEntry':
        return <AgreementModal agreementKind="PrivacyPolicy" />;
      case 'ExceliaAgreementEntry':
        return <AgreementModal agreementKind="Excelia" />;
      case 'CaptchaEntry':
        return <CaptchaEntry />;
      case 'ConfirmMemberInfoEntry':
        return <ConfirmMemberInfoEntry />;
      case 'ReviewTopBanner':
        return <></>;

      default:
        // eslint-disable-next-line no-console
        console.error(`UNIMPLEMENTED MODULE ${module}`);
        return <></>;
    }
  });
};
