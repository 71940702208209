import { flow, Instance, types } from 'mobx-state-tree';
import { NameEntryStore } from '../../../customer-information/name/NameEntryStore';
import { StudentEntryStore } from '../../../customer-information/student/StudentEntryStore';
import { GovernmentEntryStore } from '../../../customer-information/government/GovernmentEntryStore';
import { DocumentsUploadEntryStore } from '../../../customer-information/documents-upload/DocumentsUploadEntryStore';
import { EnrollmentKitSelectionStore } from '../../../order-selection/starter-kit-selection/model/EnrollmentKitStore';
import { ErKitStore } from '../../../order-selection/er-kit-selection/model/ErKitStore';
import { BillingEntryStore } from '../../../billing/model/BillingEntryStore';
import { VirtualWalletStore } from '../../../billing/components/virtual-wallet/VirtualWalletStore';
import { PhoneEntryStore } from '../../../customer-information/phone/PhoneEntryStore';
import { AccountCredentialsStore } from '../../../customer-information/account-credentials/AccountCredentialsStore';
import { EmailEntryStore } from '../../../customer-information/email/EmailEntryStore';
import { PinEntryStore } from '../../../customer-information/pin/PinEntryStore';
import { GenderEntryStore } from '../../../customer-information/gender/GenderEntryStore';
import { CoApplicantEntryStore } from '../../../customer-information/co-applicant/CoApplicantEntryStore';
import { ShippingMethodSelectionStore } from '../../../shipping/method-selection/ShippingMethodSelectionStore';
import { AgeEntryStore } from '../../../customer-information/age/AgeEntryStore';
import { PersonalInfoReviewStore } from '../../../order-review/personal-info/PersonalInfoReviewStore';
import { MembershipReviewStore } from '../../../order-review/membership-review/MembershipReviewStore';
import { ShippingAndBillingReviewStore } from '../../../order-review/shipping-and-billing-review/ShippingAndBillingReviewStore';
import { AppliedPaymentsReviewStore } from '../../../order-review/applied-payments-review/AppliedPaymentsReviewStore';
import { SponsorEnrollerEntryStore } from '../../../customer-information/sponsor-enroller/SponsorEnrollerEntryStore';
import { ShippingAddressStore } from '../../../address/shipping-address/ShippingAddressStore';
import { AddressEntryStore } from '../../../address/main-address/AddressEntryStore';
import { OrderTotalReviewStore } from '../../../order-review/order-total-review/OrderTotalReviewStore';
import { OrderSummaryStore } from '../../../order-review/order-summary/OrderSummaryStore';
import { MemberAgreementEntryStore } from '../../../customer-information/member-agreement/MemberAgreementEntryStore';
import { TaxIdEntryStore } from '../../../customer-information/tax-id/TaxIdEntryStore';
import { CompleteEnrollmentStore } from '../../../complete-enrollment/CompleteEnrollmentStore';
import { GstAgreementEntryStore } from '../../../customer-information/gst-agreement/GstAgreementEntryStore';
import { PromoVoucherEntryStore } from '../../../order-review/promo-voucher-entry/PromoVoucherEntryStore';
import { TaxFormsAgreementEntryStore } from '../../../customer-information/tax-forms-agreement/TaxFormsAgreementEntryStore';
import { EnrollmentModule, moduleMapping, EnrollmentModules } from '../../steps-management/model/LayoutModuleGroup';
import { getRootStore } from '../../../root/RootStoreUtils';
import { ClassicErKitStore } from '../../../order-selection/classic-er-kit-selection/model/ClassicErKitStore';
import { ShareYoungLivingStore } from '../../../customer-information/share-youngliving/ShareYoungLivingStore';
import { PreferencesEntryStore } from '../../../customer-information/preferences/PreferencesEntryStore';
import { ReferrerReviewStore } from '../../../order-review/sponsor-enroller-review/ReferrerReviewStore';
import { DirectMarketingAgreementEntryStore } from '../../../customer-information/direct-marketing-agreement/DirectMarketingAgreementEntryStore';
import { Redirector } from '../../../../infrastructure/redirecting/Redirector';
import { getCurrentEnrollmentId } from '../../../customer-enrollment-status/EnrollmentStatusManager';
import { MeoCartItemSummaryStore } from '../../../order-selection/minimum-enrollment-order/MeoCartItemSummaryStore';
import { OrderReturnPolicyReviewStore } from '../../../order-review/order-return-policy-review/OrderReturnPolicyReviewStore';
import { SocialLinkEntryStore } from '../../../social-link/SocialLinkEntryStore';
import { SocialLinkCreatorEntryStore } from '../../../social-link/creator-mode/SocialLinkCreatorEntryStore';
import { PrivacyPolicyAgreementEntryStore } from '../../../customer-information/privacy-policy-agreement/PrivacyPolicyAgreementEntryStore';
import { CaptchaEntryStore } from '../../../billing/components/captcha/CaptchaEntryStore';
import { ExceliaAgreementEntryStore } from '../../../customer-information/excelia-agreement/ExceliaAgreementEntryStore';
import { ThreeDSecureEntryStore } from '../../../billing/components/credit-card/three-d-secure/ThreeDSecureEntryStore';
import { ConfirmMemberInfoEntryStore } from '../../../customer-information/confirmation/ConfirmMemberInfoEntryStore';
import { ERAgreementStore } from '../../../customer-information/er-agreement/ERAgreementStore';
import { CustomerTypeEntryStore } from '../../../customer-information/customer-type/CustomerTypeEntryStore';
import { MemberAgreementOrderReviewStore } from '../../../order-review/member-agreement-order-review/MemberAgreementOrderReviewStore';

export function getAdditionalStores(stores: ActiveModuleEntry[], moduleStore: ModulesStore): ActiveModuleEntry[] {
  const storesWithAdditionalModules = stores.filter(store => store.module.additionalModules !== undefined);
  const extraModules = ([] as EnrollmentModule[]).concat(
    ...storesWithAdditionalModules.map(s => s.module.additionalModules!)
  );
  return extraModules.map(module => moduleStore.getModuleStore(module)).filter(entry => entry.module !== undefined);
}

interface ActiveModuleEntry {
  module: ModuleApi;
  key: any;
}

export const ModulesStore = types
  .model('ModuleStore', {
    socialLinkEntry: types.maybe(SocialLinkEntryStore),
    socialLinkCreatorEntry: types.maybe(SocialLinkCreatorEntryStore),
    enrollmentKitSelection: types.maybe(EnrollmentKitSelectionStore),
    erKitSelection: types.maybe(ErKitStore),
    socialLinkErKitSelection: types.maybe(ErKitStore),
    classicErKitSelection: types.maybe(ClassicErKitStore),
    meoKitSelection: types.maybe(MeoCartItemSummaryStore),
    nameEntry: types.maybe(NameEntryStore),
    studentEntry: types.maybe(StudentEntryStore),
    governmentEntry: types.maybe(GovernmentEntryStore),
    documentsUploadEntry: types.maybe(DocumentsUploadEntryStore),
    coApplicantEntry: types.maybe(CoApplicantEntryStore),
    phoneEntry: types.maybe(PhoneEntryStore),
    shippingMethodSelection: types.maybe(ShippingMethodSelectionStore),
    billingEntry: types.maybe(BillingEntryStore),
    accountCredentialsEntry: types.maybe(AccountCredentialsStore),
    emailEntry: types.maybe(EmailEntryStore),
    pinEntry: types.maybe(PinEntryStore),
    addressEntry: types.maybe(AddressEntryStore),
    genderEntry: types.maybe(GenderEntryStore),
    shippingAddressEntry: types.maybe(ShippingAddressStore),
    ageEntry: types.maybe(AgeEntryStore),
    shippingAndBillingReview: types.maybe(ShippingAndBillingReviewStore),
    membershipReview: types.maybe(MembershipReviewStore),
    referrerReview: types.maybe(ReferrerReviewStore),
    personalInfoReview: types.maybe(PersonalInfoReviewStore),
    appliedPaymentsReview: types.maybe(AppliedPaymentsReviewStore),
    sponsorEnrollerEntry: types.maybe(SponsorEnrollerEntryStore),
    orderTotalReview: types.maybe(OrderTotalReviewStore),
    orderSummary: types.maybe(OrderSummaryStore),
    memberAgreementEntry: types.maybe(MemberAgreementEntryStore),
    taxIdEntry: types.maybe(TaxIdEntryStore),
    completeEnrollmentProcessor: types.maybe(CompleteEnrollmentStore),
    gstAgreementEntry: types.maybe(GstAgreementEntryStore),
    erAgreementEntry: types.maybe(ERAgreementStore),
    taxFormsAgreementEntry: types.maybe(TaxFormsAgreementEntryStore),
    promoVoucherEntry: types.maybe(PromoVoucherEntryStore),
    preferencesEntry: types.maybe(PreferencesEntryStore),
    shareYoungLivingEntry: types.maybe(ShareYoungLivingStore),
    directMarketingAgreementEntry: types.maybe(DirectMarketingAgreementEntryStore),
    privacyPolicyAgreementEntry: types.maybe(PrivacyPolicyAgreementEntryStore),
    exceliaAgreementEntry: types.maybe(ExceliaAgreementEntryStore),
    confirmMemberInfoEntry: types.maybe(ConfirmMemberInfoEntryStore),
    captchaEntry: types.maybe(CaptchaEntryStore),
    orderReturnPolicyReview: types.maybe(OrderReturnPolicyReviewStore),
    threeDSecureEntry: types.maybe(ThreeDSecureEntryStore),
    customerTypeEntry: types.maybe(CustomerTypeEntryStore),
    memberAgreementOrderReview: types.maybe(MemberAgreementOrderReviewStore),
    completedModules: types.optional(
      types.array(types.enumeration<EnrollmentModule>('EnrollmentModule', EnrollmentModules)),
      []
    ),
    activeModuleGroup: types.maybe(types.number),
    showWelcome: types.optional(types.boolean, false),
    showCookieWarning: types.optional(types.boolean, false),
    isGlobalNfr: types.optional(types.boolean, false),
    showReturnPolicy: types.optional(types.boolean, true),
    showShippingMessage: types.optional(types.boolean, true),
    showWarning: types.optional(types.boolean, false),
    earlyEnrollment: types.optional(types.boolean, false),
    virtualWallet: types.optional(VirtualWalletStore, {}),
    allowCompletionWithoutOrder: types.optional(types.boolean, true),
    showDataProtectionSection: types.optional(types.boolean, true),
    IsShipToShopEnabled:types.optional(types.boolean, true),
    shipToShopUrl:types.optional(types.string,'https://ship-to-shop-web-stage.awsvodev.youngliving.com'),
    orderReviewAgreementEnabled: types.optional(types.boolean, false),
    weightValueForPSKEnabled: types.optional(types.boolean, false)
  })
  .views(self => ({
    getModuleStore(moduleEnum: EnrollmentModule): ActiveModuleEntry {
      const key = moduleMapping[moduleEnum] as keyof typeof self;
      const module = self[key] as ModuleApi;
      return { module, key };
    },
    get activeModules(): EnrollmentModule[] {
      const root = getRootStore(self);
      const currentStep = root.stepsManager.currentStep!;
      if (self.activeModuleGroup !== undefined) {
        return currentStep.layoutModuleGroups[self.activeModuleGroup].modules;
      }
      return currentStep.allModules;
    },
    supportsDisplay(module: EnrollmentModule): boolean {
      const moduleStore = this.getModuleStore(module).module;
      return moduleStore.supportsDisplay !== undefined && moduleStore.supportsDisplay;
    },
    get activeModuleStores(): ActiveModuleEntry[] {
      const stores = this.activeModules
        .map(module => this.getModuleStore(module))
        .filter(entry => entry.module !== undefined);
      const extraStores = getAdditionalStores(stores, self as ModulesStore);
      return stores.concat(extraStores);
    },
    get canProceed(): boolean {
      const currentEnrollmentId = getCurrentEnrollmentId();
      if (currentEnrollmentId === null) {
        Redirector.redirect('/apps/enrollment');
      }
      return this.activeModuleStores.every(({ module }) => module.canProceed === undefined || module.canProceed());
    },
    getDataToSubmit(): any {
      const rootStore = getRootStore(self);

      const form: any = {
        enrollmentId: rootStore.enrollmentStatus.enrollmentId
      };

      this.activeModuleStores.forEach(({ module, key }) => {
        if (module.getDataToSubmit) {
          form[key] = module.getDataToSubmit();
        }
      });
      return form;
    }
  }))
  .actions(self => ({
    setActiveModuleAsUndefined() {
      self.activeModuleGroup = undefined;
    },
    addCompletedModules(modules: EnrollmentModule[]) {
      modules.forEach(module => {
        if (!self.completedModules.includes(module)) {
          self.completedModules.push(module);
        }
      });
    },
    switchActiveGroup(groupIndex: number) {
      self.activeModuleGroup = groupIndex;
    },
    validateForm(): any {
      const promises = self.activeModuleStores
        .filter(({ module }) => module.validateForm)
        .map(({ module }) => module.validateForm!());
      return Promise.all(promises);
    },
    handleSaveResponse: flow(function* handleSaveResponse(): any {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of self.activeModuleStores) {
        if (entry.module.handleSaveResponse) {
          const result = yield entry.module.handleSaveResponse();
          if (!result) return false;
        }
      }
      return true;
    }),
    prepareResults: flow(function* prepareResults(): any {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of self.activeModuleStores) {
        if (entry.module.prepareResults) {
          const result = yield entry.module.prepareResults();
          if (!result) {
            return false;
          }
        }
      }
      return true;
    })
  }));

export type ModulesStore = Instance<typeof ModulesStore>;

export interface ModuleApi {
  handleSaveResponse?: () => Promise<boolean>;
  prepareResults?: () => Promise<boolean>;
  canProceed?: () => boolean;
  supportsDisplay?: boolean;
  getDataToSubmit?: () => any;
  validateForm?: () => Promise<void>;
  additionalModules?: EnrollmentModule[];
}

export type ModuleKey = keyof ModulesStore;
