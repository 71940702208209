import { Instance, types } from 'mobx-state-tree';

export const Currency = types.model('Currency', {
  code: types.identifier,
  symbol: types.string
});

export type Currency = Instance<typeof Currency>;

export function getDecimalPrice(price: number, code = '') {
  if (code === 'JPY' || code === 'IDR') {
    const locale = 'en-' + code.slice(0, 2);
    return (price / 100).toLocaleString(locale, { maximumFractionDigits: 0, minimumFractionDigits: 0 });
  } else {
    return (price / 100).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }
}

export function formatAmount(amount: number, roundFactor: number): string {
  let ret: string = amount.toString();
  if (roundFactor != 0) {
    ret = (amount / Math.pow(10, roundFactor)).toFixed(roundFactor);
  }
  return ret;
}
