import { addDisposer, flow, Instance, types } from 'mobx-state-tree';
import { FieldState, FormState } from 'formstate';
import { getFormValues } from '@yl/react-forms';
import { when } from 'mobx';

import { apiClient, cvvRegEx, cvvUnallowedRegEx, getEncrypter } from '../../billing/model/CreditCard';
import { replaceUnallowedCharacters } from '../../../infrastructure/forms/InputTextFormatter';
import { required } from '../../customer-information/shared/validators/RequiredValidator';
import { matchesRegExp } from '../../customer-information/shared/validators/RegularExpressionValidator';

export const CvvForPurchaseModalStore = types
  .model({
    showModal: types.optional(types.boolean, false)
  })
  .volatile(() => ({
    deferred: undefined as ((value: boolean) => void) | undefined
  }))
  .views(() => ({
    get reactForm() {
      const cvv = new FieldState('').validators(required(), matchesRegExp(cvvRegEx)).disableAutoValidation();
      return new FormState({ cvv });
    }
  }))
  .actions(self => ({
    applyFormat: flow(function* debounceFormat(field: FieldState<any>) {
      yield new Promise(r => setTimeout(r, 1000));
      if (field === self.reactForm.$.cvv) {
        replaceUnallowedCharacters(field, cvvUnallowedRegEx);
      }
    }),
    hideModal() {
      self.showModal = false;
      if (self.deferred) {
        self.deferred(false);
      }
    },
    associateCvv: flow(function* associateCvv() {
      yield self.reactForm.validate();

      if (self.reactForm.hasError) {
        return;
      }
      self.showModal = false;
      self.deferred!(true);
    }),
    showCvvModal: flow(function* showCvvModal() {
      self.showModal = true;
      const shouldContinue: boolean = yield new Promise<boolean>(resolve => {
        self.deferred = resolve;
      });

      if (!shouldContinue) return false;

      const form = getFormValues(self.reactForm);

      const encrypter: Awaited<ReturnType<typeof getEncrypter>> = yield getEncrypter();
      const encryptedCvv = encrypter.encrypt(form.cvv);

      yield apiClient.associateCvvWithCreditCard({ encryptedCvv });

      return true;
    })
  }))
  .actions(self => ({
    afterCreate() {
      addDisposer(
        self,
        when(
          () => self.showModal,
          () => getEncrypter()
        )
      );
    }
  }));

export type CvvForPurchaseModalStore = Instance<typeof CvvForPurchaseModalStore>;
