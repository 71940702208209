import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Form, FormGroup, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { YlDropdown } from '../../../../infrastructure/forms/YlDropdown';
import { YlInput } from '../../../../infrastructure/forms/YlInput';
import { months } from '../../../../reference/MonthOptions';
import { generateYears } from '../../../../reference/YearOptions';
import { BillingAddressEntry } from './billing-address/BillingAddressEntry';
import { translateMonth } from '../../../../reference/MonthName';
import { useRootStore } from '../../../root/RootStoreHooks';
import { ThreeDSecureWarningModal } from './three-d-secure/ThreeDSecureWarningModal';
import { CreditCardTypes } from '../../../../external/shared/api/EnrollmentClient.generated';

const CreditCardLogos = observer(() => {
  const { t } = useTranslation();
  const { threeDSecureEntry } = useRootStore().moduleStores;
  return (
    <>
      <img alt={t('CCAltImage')} src={t('CCImageLink')} />
      {threeDSecureEntry?.config.cybersource3dsEnabled && (
        <img alt={t('CCAltImage')} className="mt-1" src={t('CCVisaLogo')} />
      )}
    </>
  );
});

export const CreditCardEditor = observer(() => {
  const { billingEntry } = useRootStore().moduleStores;
  const { t } = useTranslation();
  const cc = billingEntry!.creditCardData;
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: t('BillingInformationHelp') }}></p>
      <ThreeDSecureWarningModal show={cc.asyncPayment.show3dsWarningModal} onHide={cc.asyncPayment.close3dsWarning} />
      <Form className="mb-n3">
        <Form.Row>
          <YlInput
            size={6}
            id="cardHolder"
            required
            field={cc.reactForm.$.name}
            label="NameOnCard"
            disabled={cc.displayOnly}
            customAttributes={{ autoComplete: 'cc-name' }}
          />
        </Form.Row>
        <Form.Row>
          <YlInput
            required
            size={8}
            field={cc.reactForm.$.number}
            label="CardNumber"
            id="cardNumber"
            customAttributes={{ maxLength: 16, autoComplete: 'cc-number' }}
            disabled={cc.displayOnly}
            append={
              <InputGroup.Append as={Col} md={3} className="align-items-center d-none d-md-flex">
                <CreditCardLogos />
              </InputGroup.Append>
            }
          />
          <Form.Group className="col-12 d-flex d-md-none mb-n3 justify-content-end">
            <CreditCardLogos />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col md={6} lg={4}>
            <Form.Row>
              <YlDropdown
                required
                size={6}
                id="expMonth"
                options={months}
                valueFn={month => month}
                labelFn={month => `${month} - ${translateMonth(month - 1)}`}
                disabled={cc.displayOnly}
                field={cc.reactForm.$.expirationMonth}
                label="ExpirationMonth"
                customAttributes={{ autoComplete: 'cc-exp-month' }}
              />
              <YlDropdown
                required
                id="expYear"
                size={6}
                options={generateYears(20)}
                valueFn={year => year}
                labelFn={year => year}
                disabled={cc.displayOnly}
                field={cc.reactForm.$.expirationYear}
                label="ExpirationYear"
                onChange={() => cc.reactForm.$.expirationMonth.validate()}
                customAttributes={{ autoComplete: 'cc-exp-year' }}
              />
            </Form.Row>
          </Col>
          <Col md={12} lg={8}>
            <Form.Row>
              {cc.config.requireCvv && (
                <YlInput
                  required
                  id="cvv"
                  size={3}
                  type="password"
                  field={cc.reactForm.$.cvv}
                  label="Credit_CCV"
                  customAttributes={{
                    autoComplete: 'cc-csc',
                    minLength: 3,
                    maxLength: cc.matchedCardType?.value === CreditCardTypes.AMEX ? 4 : 3
                  }}
                  disabled={cc.displayOnly}
                  onChange={() => cc.applyFormat(cc.reactForm.$.cvv)}
                />
              )}
            </Form.Row>
          </Col>
        </Form.Row>
        {cc.config.showRefundWarning && (
          <Form.Row>
            <Col xs>
              <p>
                <b>{t('RefundWarning')}</b>
              </p>
            </Col>
          </Form.Row>
        )}
        {billingEntry!.shouldCollectBillingAddress && (
          <Form.Row>
            <Col xs>
              <BillingAddressEntry disabled={cc.displayOnly} />
            </Col>
          </Form.Row>
        )}
        {cc.displayOnly && (
          <Form.Row>
            <FormGroup>
              <Button onClick={() => billingEntry!.editCreditCard()} variant="outline-primary">
                {t('Edit')}
              </Button>
            </FormGroup>
          </Form.Row>
        )}
      </Form>
    </>
  );
});
